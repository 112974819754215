/* eslint-disable */
import React, { useState, useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import {selectiDB, selectVersion, updateState} from "../../features/counter/counterSlice";
const downloadWorker = new Worker(new URL('../../workers/download.js', import.meta.url), { type: 'module' });

const NavBar = React.lazy(() => import('../../components/navBar'));
const TopicBanner = React.lazy(() => import('../../components/topicBanner'));
const Footer = React.lazy(() => import('../../components/footer'));



const Import = (props) => {
  const { pathname, search, push } = props;
  const iDB = useSelector(selectiDB);
  const [idbKeys,setIdbKeys] = useState(0);
  const ccmsversion = useSelector(selectVersion);
  const [version, setVersion] = useState(ccmsversion.selected);
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);

  const handleFileChange = (evt) => {
    setResult([]);
    const files = evt.target.files;
    for (let i = 0; i < files.length; i++) {
      handleFile(files[i]);
    }
  };

  useEffect(() => {
    iDB.keys().then(keys => {
      setIdbKeys(keys);
        // console.log("keys:",keys);
        console.log(ccmsversion);
  });
  }, []);

  const handleClearCache = () => {
      iDB.clear().then(() => {
        console.log('Cleared iDB.');
        iDB.keys().then(keys => {setIdbKeys(keys);console.log("keys:",keys);});
      });
      
  }

  const handleFile = (f) => {
    const dateBefore = new Date();
    downloadWorker.postMessage({ command: 'Import',buffer:f,idbKeys,ccmsversion:version});
    downloadWorker.addEventListener('message', (event) => {
        console.log('Message from worker:', event.data);
    });

    // JSZip.loadAsync(f)
    //   .then((zip) => {
    //     const dateAfter = new Date();
    //     const fileContent = [];
    //     zip.forEach((relativePath, zipEntry) => {
    //       fileContent.push(zipEntry.name);
    //     });
    //     setResult((prevResult) => [
    //       ...prevResult,
    //       {
    //         title: `${f.name} (loaded in ${dateAfter - dateBefore}ms)`,
    //         content: fileContent,
    //       },
    //     ]);
    //   })
    //   .catch((e) => {
    //     setResult((prevResult) => [
    //       ...prevResult,
    //       {
    //         title: `Error reading ${f.name}`,
    //         content: [e.message],
    //       },
    //     ]);
    //   });
  };

  const customColumnStyle = {
    minHeight: '200px',
    padding:'10px',
    paddingBottom: '10px',
    borderLeft: '10px solid #fff',
  };

  const handleVersionChange=(e)=>{
    setVersion(e.target.value);
    console.log(e.target.value);
    dispatch(updateState({ version:{...ccmsversion, selected: e.target.value}}));
    iDB.config({ name: e.target.value });

  }

  return (
    <>
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
      <TopicBanner pathname={pathname} search={search} push={push} />
      <MsalAuthenticationTemplate>
          <div className="container" style={{ height: '50vh' }}>

            <div className="row">
            <div className="col-md-4 bg-success" style={customColumnStyle}>
                <h3 className="text-center">1. Select Content Slot</h3>
                
                  <div className="form-group">
                    <label htmlFor="options">Current slot:</label>
                    <select className="form-control" id="options" value={version} onChange={(e)=>handleVersionChange(e)} >
                      {ccmsversion.items.map((item,index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                
                <p> </p>
                <p> </p>
              </div>
              <div className="col-md-4 bg-warning" style={customColumnStyle}>
                <h3 className="text-center">2. Clear Cache?</h3>
                <p>There are {idbKeys.length} keys in the Cache</p>
                <button type="button" onClick={(e) => handleClearCache(e)} className="btn btn-primary">Clear Cache</button>
              </div>
              <div className="col-md-4 bg-info" style={customColumnStyle}>
                <h3 className="text-center">3. Upload a local zip file</h3>
                <div>
                  <input type="file" id="file" name="file" multiple onChange={handleFileChange} className="form-control" />
                  {result.map((file, index) => (
                    <div key={index}>
                      <h4>{file.title}</h4>
                      <ul>
                        {file.content.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 bg-default">
                <p> </p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 bg-success" style={customColumnStyle}>
                <h3 className="text-center">4. Build Search Index</h3>
                <button type="button" className="btn btn-primary">Start Indexing content</button>
                <p> </p>
                <p> </p>
              </div>
            </div>


          </div>
        </MsalAuthenticationTemplate>
      <Footer />
    </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Import);
